import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ClientService } from '../clients/client.service';
import { Client } from '../clients/client';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private clientService: ClientService
  ) { }

  /** Returns the current user from Firebase as an Observable */
  get userData(): Observable<any> {
    return this.auth.user.pipe(
      switchMap((user: any) => {
        return this.clientService.allClients(user.uid).pipe(
          map((clients: Client[]) => {
            return { displayName: user.displayName, userId: user.uid, photoUrl: user.photoUrl, clients }
          })
        );
      })
    );
  }
}
