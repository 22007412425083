<div *ngIf="userData$ | async as userData; else loading" class="dashboard">
  
  <div class="account-menu">
    <mat-icon [matMenuTriggerFor]="menu" color="primary">account_circle</mat-icon>
    <mat-menu #menu="matMenu">
      <button mat-menu-item color="warn" (click)="logout()">Delete Client</button>
    </mat-menu>
  </div>
  
  <div class="overview">

    <div class="welcome-container">
      <div class="welcome-text">Hello {{ userData.displayName }}</div>
      <div class="welcome-subtext">Welcome back to Simple Logger</div>
    </div>
  
    <div class="new-client" (click)="selectClient('new')">Add a New Client</div>
  
    <div class="clients">
      <div class="clients-header">Your Clients</div>
  
      <div class="client-cards">
        <div class="client-card" *ngFor="let client of userData.clients" (click)="selectClient(client.clientName, client)">
          {{ client.abbreviation }}
        </div>
        <div *ngIf="!userData.clients || !userData.clients.length" class="client-card" (click)="selectClient('new')">
          <mat-icon>add</mat-icon>
        </div>
      </div>
      
    </div>
  </div>

  <div class="client-details" *ngIf="detailView$ | async as dView">
    <app-new-client [userId]="userData.userId" *ngIf="dView === 'new'"></app-new-client>
    <app-view-client [client]="currentClient" *ngIf="dView !== 'new'"></app-view-client>
  </div>
</div>

<ng-template #loading>
  <div class="loading">Loading</div>
</ng-template>
