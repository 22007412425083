import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { tap, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

/** Protects routes that allow only a logged in user */
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AngularFireAuth,
    private router: Router
  ) { }

  /**
   * AuthGuard.canActivate is called every time a route protected by AuthGuard is activated
   *
   * canActivate returns whether or not the given route is allowed
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.auth.user.pipe(
      tap((user: any) => !user && this.router.navigateByUrl('/login')),
      map((user: any) => !!user)
    )
  }
}