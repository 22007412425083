import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Client, Log } from '../clients/client';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { ClientService } from '../clients/client.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MAT_CHECKBOX_CLICK_ACTION } from '@angular/material/checkbox';
import { AngularFireAuth } from '@angular/fire/auth';
import { saveAs } from 'file-saver';

const monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

@Component({
  selector: 'app-view-client',
  templateUrl: './view-client.component.html',
  styleUrls: ['./view-client.component.scss'],
  providers: [
    { provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'noop' }
  ],
  animations: [
    trigger('expandLog', [
      state('true', style({ 
        overflow: '*',
        height: '*',
        'min-height': '*',
        display: '*',
        'margin-top': '16px'
      })),
      state('false', style({ 
        overflow: 'hidden',
        height: '0',
        'min-height': '0px',
        'margin-top': '0',
        display: '*' 
      })),
      transition('true => false', animate('.2s')),
      transition('false => true', animate('.2s .2s'))
    ])
  ]
})
export class ViewClientComponent implements OnInit {

  /** Component input for the current userId */
  @Input() client: Client;

  /** Form for a new log */
  newLogForm: FormGroup;

  /** All Logs for the current Client sorted by month */
  sortedLogs: { timeSection: string, logs: Log & { selected: boolean }[] }[];

  /** Whether or not the new log form is expanded */
  showNewLog: boolean = false;

  /** The previously selected log index */
  lastSelected: any;

  /** Form element for creating a new Client */
  @ViewChild('form') form: FormGroupDirective;

  constructor(
    private fb: FormBuilder,
    public clientService: ClientService,
    private auth: AngularFireAuth,
  ) {
    this.newLogForm = this.fb.group({
      description: ['', Validators.required],
      date: [new Date(), Validators.required],
      hours: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    // this.auth.user.subscribe((user: any) => {

    // });
  }

  /** Selects the clicked log or a range between two logs */
  selectLogs(event: MouseEvent, logData: Log & { selected: boolean }, sortedLogs: any): any {
    if (event.shiftKey) {
      let isInverted = this.lastSelected > logData.date;
      let low = isInverted ? logData.date : this.lastSelected;
      let high = isInverted ? this.lastSelected : logData.date;

      let shouldSelect = !logData.selected

      let newSortedLogs = [...sortedLogs];

      newSortedLogs.forEach(({logs}) => logs.forEach((log: any) => {
        if (log.date >= low && log.date <= high) log.selected = shouldSelect;
      }));

      this.clientService.updateSortedLogs(newSortedLogs);

    } else logData.selected = !logData.selected

    this.lastSelected = logData.date;
  }

  /** Deletes current Client and navigates to New Client page */
  deleteClient(): void {
    this.clientService.deleteClient(this.client.id);
    this.clientService.currentClient('new');
  }

  /** Exports selected logs to a CSV and downloads the file */
  exportLogs(sortedLogs: any): void {
    /** An array of all logs that are marked as selected */
    let selectedLogs = sortedLogs.reduce((acc: any[], {logs}) => {
      let selectedSection = logs.filter(({selected}) => selected).map(({date, description, hours}) => ({ date: this.formatDate(date), description, hours }));
      if (selectedSection.length) return [...acc, ...selectedSection];
      return acc;
    }, []);

    // Only create file if at least one log is selected
    if (selectedLogs.length) {
      // Calculate total hours and price
      let sum = (array: number[]): number => array.reduce((a, b) => a + b)
      let totalHours = sum(selectedLogs.map(({hours}) => hours));
      let totalPrice = totalHours * this.client['hourlyRate'] || 20;
  
      // Customize null values for each cell
      const replacer = (key: any, value: any) => value === null ? '' : value;
      // Create header for csv
      let header = ['Date', 'Description', 'Hours'];
      // Create main body of csv file
      let csv = selectedLogs.map((log: any) => header.map((fieldName: string) => JSON.stringify(log[fieldName.toLowerCase()], replacer)).join(','));
      csv.unshift(header.join(','));
  
      // Add total columns to the file
      csv.push([' ', ' ', ' '].toString());
      csv.push([' ', 'Total Hours', totalHours].toString());
      csv.push([' ', 'Total Price', totalPrice].toString());
      let csvArray = csv.join('\r\n');
  
      // Create <a> tag with csv download link
      var a = document.createElement('a');
      var blob = new Blob([csvArray], {type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);
  
      // Downloads the file via <a> ref
      a.href = url;
      a.download = `${this.client.clientName} Work Log ${selectedLogs[0].date} - ${selectedLogs[selectedLogs.length - 1].date}`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  /** Removes selected logs from the database */
  removeLogs(sortedLogs: any[]): void {
    /** An array of all logs that are marked as selected */
    let selectedLogs = sortedLogs.reduce((acc: any[], {logs}) => {
      let selectedSection = logs.filter(({selected}) => selected).map(({ date, description, hours }) => ({ date, description, hours }));
      if (selectedSection.length) return [...acc, ...selectedSection];
      return acc;
    }, []);
    // Send logs to be removed to the database
    this.clientService.removeLogs(this.client, selectedLogs);
    /** The remaining sections and logs of sortedLogs */
    let remainingLogs = sortedLogs.map(({timeSection, logs}) => {
      return { timeSection, logs: logs.filter((log: any) => {
        return !(selectedLogs.some(({date, description, hours}) => log.date === date && log.description === description && log.hours === hours ));
      })};
    });

    this.client.logs = remainingLogs.reduce((acc: any[], {logs}) => {
      return [...acc, ...logs];
    }, []);
    // Update display of sortedLogs
    this.clientService.updateSortedLogs(remainingLogs || []);
  }

  /** Formats date for CSV file */
  formatDate(d: Date): string {
    const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' }) 
    const [{ value: mo },,{ value: da },,{ value: ye }] = dtf.formatToParts(d);
    return `${mo}-${da}-${ye}`;
  }

  /** Toggles the visibility of the new log form */
  toggleLogForm(): void {
    this.showNewLog = !this.showNewLog;
  }

  /** Submits a new log under the current Client to Firestore */
  submitLog(sortedLogs?: any): any {
    /** The newly created log */
    let log: Log = { 
      date: this.newLogForm.get('date').value, 
      description: this.newLogForm.get('description').value, 
      hours: this.newLogForm.get('hours').value 
    };
    // Send new log to database
    this.clientService.createLog(this.client, log);
    // Reset new log form errors and values
    this.form.resetForm();
    // Update display of sortedLogs
    this.client.logs.push(log);
    this.clientService.changeSortedLogs(this.client.logs);
  }

}
