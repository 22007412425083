
<div class="new-client">

  <div>
    <div class="new-client-header">New Client</div>
  
    <form [formGroup]="newClientForm" class="new-client-form" (ngSubmit)="submitClient()" #form="ngForm">
  
      <mat-form-field>
        <input matInput formControlName="clientName" placeholder="Client Name">
      </mat-form-field>
  
      <mat-form-field>
        <input matInput formControlName="abbreviation" placeholder="Abbreviation">
      </mat-form-field>

      <mat-form-field>
        <span matPrefix>$ &nbsp;</span>
        <input matInput formControlName="rate" placeholder="Hourly Rate" type="number">
      </mat-form-field>

      <button class="submit-new-client-button" type="submit">Create Client</button>
  
    </form>
  </div>

</div>
