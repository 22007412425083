// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCKunIEB5vbYinvSs-C4QxPDf5IgNOhqGQ",
    authDomain: "simple-logger-4c8fd.firebaseapp.com",
    databaseURL: "https://simple-logger-4c8fd.firebaseio.com",
    projectId: "simple-logger-4c8fd",
    storageBucket: "simple-logger-4c8fd.appspot.com",
    messagingSenderId: "518734072278",
    appId: "1:518734072278:web:38e3a8cb28664ec0a23c77",
    measurementId: "G-VWLBGWP1SS"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
