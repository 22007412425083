import { AngularFirestore } from '@angular/fire/firestore'

import { firestore } from 'firebase/app';

/** Layout of a single work Log for a Client */
export interface Log {
  date: Date,
  description: string,
  hours: number,
}

/** Represents a single client for a user in which they record their work hours under */
export class Client {

  constructor(
    public userId: string,
    public clientName: string,
    public logs: Log[] = [],
    public abbreviation?: string,
    public id: any = null,
    public hourlyRate: number = 20
  ) {
    if (!this.abbreviation) {
      // Create an abbreviation if it wasn't specified
      let matches = this.abbreviation.match(/\b(\w)/g);
      this.abbreviation = matches.join('');
    }
  }

  static clientConverter = {
    toFirestore: function(client: Client) {
      // Format log dates to Firestore Timestamps
      let firestoreLogs = client['logs'] && client['logs'].length ? client.logs.map(({date, description, hours}) => {
        return { date: createTimestamp(date), description, hours }
      }) : [];

      return {
        userId: client.userId,
        clientName: client.clientName,
        logs: firestoreLogs,
        abbreviation: client.abbreviation,
        hourlyRate: client['hourlyRate'] || 20
      }
    },
    fromFirestore: function(snapshot: any, options: any){
      const data = snapshot.data(options);
      let displayLogs = data.logs ? data.logs.map(({date, description, hours}) => {
        return { date: createDate(date), description, hours }
      }).sort((a: any, b: any) => a.date - b.date) : null;
      return new Client(data.userId, data.clientName, displayLogs, data.abbreviation, snapshot.id, data.hourlyRate);
    }
  }

}

let createTimestamp = (date: Date = new Date()): firestore.Timestamp => firestore.Timestamp.fromDate(date);
let createDate = (timestamp: firestore.Timestamp): Date => timestamp.toDate();
