import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormGroupDirective } from '@angular/forms';
import { ClientService } from '../clients/client.service';
import { Client } from '../clients/client';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss']
})
export class NewClientComponent implements OnInit {

  /** Component input for the current userId */
  @Input() userId: string;
  /** Form Group for creating a new Client */
  newClientForm: FormGroup;
  /** Form element for creating a new Client */
  @ViewChild('form') form: FormGroupDirective;

  constructor(
    private fb: FormBuilder,
    private clientService: ClientService,
  ) {
    this.newClientForm = this.fb.group({
      clientName: ['', Validators.required],
      abbreviation: ['', Validators.maxLength(5)],
      rate: ['', Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(1000)
      ])]
    })
  }

  ngOnInit(): void {
  }

  /** Submits new Client to be created in Firestore */
  submitClient(): void {
    if (!Object.values(this.newClientForm.controls).some((control: AbstractControl) => control.invalid)) {
      let newClient = new Client(this.userId, this.newClientForm.get('clientName').value, null, this.newClientForm.get('abbreviation').value || null, null, this.newClientForm.get('rate').value);
      this.clientService.createClient(newClient);
      // Reset the form errors and values
      this.form.resetForm();
    }
  }

}
