import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { ClientService } from '../clients/client.service';
import { Client } from '../clients/client';
import { tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  /** Observable that returns relevant data about the current user */
  userData$: Observable<any>;
  /** String reference to the current client being viewed or new client form emitted as an Observable */
  detailView$: Observable<string>;

  /** String reference to the current client being viewed or new client form */
  detailView: string = 'new';
  /** The current client being viewed */
  currentClient: Client | null = null;

  /** Whether or not the initally select Client has been determined */
  initialClientSelected: boolean = false;

  constructor(
    private dashboardService: DashboardService,
    private clientService: ClientService,
    private auth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userData$ = this.dashboardService.userData.pipe(
      tap(({clients}) => {
        // Sets the current client to the first in the array
        // as long as it hasn't been previously set
        if (!this.initialClientSelected) {
          this.initialClientSelected = true;
          if (clients && clients.length) {
            this.clientService.currentClient(clients[0].clientName);
            this.clientService.changeSortedLogs(clients[0].logs || []);
            this.currentClient = clients[0];
          } else this.clientService.currentClient('new');
        }
      })
    );
    this.detailView$ = this.clientService.activeClientName$;
  }

  /** Changes the current detailView */
  selectClient(clientName: string, client?: Client): void {
    if (client) {
      this.clientService.currentClient(clientName);
      this.clientService.changeSortedLogs(client.logs || []);
      this.currentClient = client;
    } else this.clientService.currentClient('new');
  }

  /** Signs out current user */
  logout(): any {
    this.auth.signOut()
      .then(_ => this.router.navigateByUrl('/home'))
      .catch(err => console.log('Error logging out:', err));
  }


}
