import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { Client, Log } from './client';
import { AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';

const monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  
  /** Behavior subject that stores the sorted logs for the current Client */
  public sortedLogs$: BehaviorSubject<{ timeSection: string, logs: Log & { selected: boolean }[] }[]>;
  /** Behavior Subject that stores the currently active Client name */
  public activeClientName$: BehaviorSubject<string>;

  constructor(
    private firestore: AngularFirestore,
  ) {
    this.sortedLogs$ = new BehaviorSubject<{ timeSection: string, logs: Log & { selected: boolean }[] }[]>(null);
    this.activeClientName$ = new BehaviorSubject<string>(null);
  }

  /** Returns all clients and their related data for the current user */
  allClients(userId: string): Observable<any> {
    return this.firestore.collection(this.collectionWithConverter(), ref => ref.where('userId', '==', userId)).valueChanges();
  }
  
  /** Sends the form data for the new Client to Firestore */
  createClient(client: Client): any {
    this.firestore.collection(this.collectionWithConverter()).add(client).catch((err: any) => console.log('Error creating client:', err))
  }

  /** Updates the currently selected/active Client */
  currentClient(name: string): any {
    this.activeClientName$.next(name);
  }

  /** Changes sortedLog$ with the new log data */
  changeSortedLogs(logs: Log[]): void {
    // Ensure the logs are sorted by oldest to newest
    logs.sort((a, b) => a.date < b.date ? -1 : a.date > b.date ? 1 : 0);
    let sortedLogs = logs ? logs.reduce((logSections: any, log: Log) => {
      let currentTimeSection = `${monthNames[log.date.getMonth()]} ${log.date.getFullYear()}`;

      if (logSections.length && logSections.some(({ timeSection }) => timeSection === currentTimeSection)) {
        let timeSectionIndex = logSections.findIndex(({ timeSection }) => timeSection === currentTimeSection);
        logSections[timeSectionIndex].logs.push({...log, ...{ selected: false }});
        return logSections;
      }
      logSections.push({ timeSection: currentTimeSection, logs: [{...log, ...{ selected: false }}] });
      return logSections;
    }, []) : [];
    let newSortedLogs = sortedLogs.filter(({logs}) => logs && logs.length);
    // Filter out any timeSection with no Logs or any empty array
    this.sortedLogs$.next(newSortedLogs);
  }

  /** Updates the current data in sortedLog$ */
  updateSortedLogs(sortedLogs: { timeSection: string, logs: Log & { selected: boolean }[] }[]): void {
    // Filter out any timeSection with no Logs or any empty array
    let newSortedLogs = sortedLogs.filter(({logs}) => logs && logs.length);
    this.sortedLogs$.next(newSortedLogs);
  }

  /** Sends a new log for a Client to Firestore */
  createLog(client: Client, log: Log): any {
    let updatingClient = this.firestore.collection(this.collectionWithConverter()).doc(client.id).update({
      logs: firestore.FieldValue.arrayUnion(log)
    }).catch((err: any) => console.log('Error adding work Log to Client:', err));
  }

  /** Removes specified logs from Client in Firestore */
  removeLogs(client: Client, logs: Log[]): any {
    logs && logs.length && this.firestore.collection(this.collectionWithConverter()).doc(client.id).update({
      logs: firestore.FieldValue.arrayRemove(...logs)
    }).catch((err: any) => console.log('Error removing work Logs from Client:', err));
  }

  /** Deletes a given Client by its id */
  deleteClient(clientId: Client['id']): any {
    this.firestore.collection(this.collectionWithConverter()).doc(clientId).delete()
      .catch((err: any) => console.log('Error deleteing Client:', err));
  }
  
  /** Returns the collection with the converter method */
  collectionWithConverter(): CollectionReference {
    return this.firestore.firestore.collection('clients').withConverter(Client.clientConverter)
  }
}
