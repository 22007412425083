
<div class="login-page">

  <!-- <div class="google-login" >Sign In with Google</div> -->
  <div class="g-signin2" data-onsuccess="onSignIn"></div>


  <div class="login-card">
    <div class="login-header">Sign In or Sign Up</div>
    <img src="assets/images/btn_google_signin_light_normal_web@2x.png" alt="Sign In with Google" (click)="login()">
  </div>

</div>
