<div class="client-view" *ngIf="client">

  <div class="client-upper-section">
    <div class="client-header">
      <div class="client-name">{{ client.clientName }}</div>
    </div>
  
    <div class="logs-container" *ngIf="(clientService.sortedLogs$ | async) as sortedLogsData">
      <div class="logs-header">
        <div>Your Logs</div>
        <div class="logs-actions">
          <div><mat-icon color="primary" (click)="exportLogs(sortedLogsData)">save_alt</mat-icon></div>
          <div><mat-icon color="primary" (click)="removeLogs(sortedLogsData)">delete</mat-icon></div>
          <div>
            <mat-icon [matMenuTriggerFor]="menu" color="primary">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
              <button mat-menu-item color="warn" (click)="deleteClient()">Delete Client</button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div class="logs">
  
        <div class="logs-section" *ngFor="let logSection of sortedLogsData">

          <div class="logs-section-header">{{ logSection.timeSection }}</div>
          <div class="log" *ngFor="let log of logSection.logs">
            <div class="log-left-section">
              <div class="log-actions">
                <mat-checkbox color="primary" [checked]="log.selected" (click)="selectLogs($event, log, sortedLogsData)"></mat-checkbox>
              </div>
              <div class="log-main">
                <div class="log-description">{{ log.description }}</div>
                <div class="log-hours">{{ log.hours }} Hours</div>
              </div>
            </div>
  
            <div class="log-date">{{ log.date | date : 'EEEE, dd' }}</div>
          </div>
        </div>
  
  
      </div>
    </div>
  </div>


  <div class="new-log" *ngIf="(clientService.sortedLogs$ | async) as sortedLogsData">
    <div class="new-log-header" (click)="toggleLogForm()">
      <div>New Work Log</div>
      <div>
        <mat-icon>{{ showNewLog ? 'expand_less' : 'expand_more' }}</mat-icon>
      </div>
    </div>
    <!-- [style.marginBottom]="showNewLog ? '16px' : '0'" -->
    <form class="new-log-form" #form="ngForm" (ngSubmit)="submitLog(sortedLogsData)" [formGroup]="newLogForm"  [@expandLog]="showNewLog">
      
      <div class="dark-theme-field">
        <input formControlName="description" placeholder="Description">
      </div>

      <div class="lower-form-area">
        <div class="dark-theme-field">
          <input formControlName="hours" placeholder="Hours Worked" type="number">
        </div>
  
        <div class="dark-theme-field">
          <input formControlName="date" [matDatepicker]="logdatepicker">
          <mat-datepicker-toggle matSuffix [for]="logdatepicker"></mat-datepicker-toggle>
          <mat-datepicker #logdatepicker></mat-datepicker>
        </div>
      </div>

      <button class="submit-log-button">Create Log</button>


      <!-- <mat-form-field appearance="outline" color="accent">
        <input matInput [matDatepicker]="logdatepicker" formControlName="date" placeholder="Log Date">
        <mat-datepicker-toggle matSuffix [for]="logdatepicker"></mat-datepicker-toggle>
        <mat-datepicker #logdatepicker></mat-datepicker>
      </mat-form-field> -->

    </form>
  </div>

</div>